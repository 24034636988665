<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      @getList="getList"
      @clearSearch="clearSearch"
    >
      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.order_no"
          size="small"
          clearable
          placeholder="按订单号查询"
        ></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.nickname"
          size="small"
          clearable
          placeholder="按微信号查询"
        ></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select
          v-model="searchFormData.operate_type"
          placeholder="按操作类型查询"
          clearable
          size="small"
        >
          <el-option
            label="订单"
            :value="10"
          >
          </el-option>
          <el-option
            label="后台操作"
            :value="20"
          >
          </el-option>
          <el-option
            label="退款"
            :value="30"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="order_no"
        title="订单号"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="gift_money"
        title="赠送金额"
        align="center"
        min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        field="operate_type"
        title="操作类型"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.operate_type[row.operate_type]||'未知类型'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="u_type"
        title="用户信息"
        align="center"
        min-width="100px"
      >
        <template v-slot="{ row }">
          <span v-if="row.u_type==10">微信-{{row.nickname||'匿名用户'}}</span>
          <span v-if="row.u_type==20">电卡-{{row.cardno}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="create_time"
        title="创建时间"
        align="center"
        min-width="100px"
      />
    </Tables>
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'user-giftlog-list',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '赠送金列表',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {
        u_type: 10
      },
      types: {
        operate_type: {
          10: '订单',
          20: '后台操作',
          30: '退款'
        }
      }
    }
  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await this.$api.Fans.GetGiftlogList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {
        u_type: 10
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
